import React from "react";
import Cards from "./components/cards";
function App() {
  return (
    <React.Fragment>
      <main className="container">
        <Cards />
      </main>
    </React.Fragment>
  );
}

export default App;
